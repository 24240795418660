import { Component } from "react";

import { FormGroup, Input, Label, FormFeedback, Button } from 'reactstrap';

class Btn extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {


        return (
            <FormGroup className="position-relative">
                <Button size={this.props.size} type={this.props.type} className={this.props.className} onClick={this.props.onClick}>{this.props.children}</Button>
            </FormGroup>
        )
    }
}

export default Btn;
