import React from 'react';
import Page from '../../containers/page';
import FormBuilder from '../../form-builder';
import RouteComponent from '../../helpers/route';


class Home extends RouteComponent {
    constructor(props) {
        super(props);
        this.state = {
            articlesList: true,
            form: false,
            total: 20,
            items: [

            ],
            articleTypes: [],
            groups: [],
            subgroups: [],
            manufacturers: [],
            atc: [],
            shapes: [],
            modes: [],
            attributes: [],
            categories: [],
        }
    }



    componentDidMount() {
        this._onLoad();

        this._api('categories', {}, (data) => {
            this.setState({ categories: data.categories })
        })

    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props[0].location.search != prevProps[0].location.search) {
            this._onLoad();
        }
    }

    render() {
        let params = this._getParams();

        console.log(this.state.categories)
        return (
            <div>
                <div className="section-title title-options">
                    <h1>{'App config'.translate(this.props.lang)}</h1>
                </div>

                {
                    this.state.form ?

                        <div>
                            <div className="side-margin">
                                <FormBuilder
                                    registerSubmitAction={(submit) => this.submit = submit}
                                    classNameForm={"add-form-container"}
                                    // headerForm={"Artikal".translate(this.props.lang)}
                                    //                                  addButtonText={this.state.form._id ? 'Edit product'.translate(this.props.lang) :  'Add new'.translate(this.props.lang)}
                                    lang={this.props.lang}
                                    onSubmit={(data) => {

                                        this._api('save', { data: data }, () => {
                                            this.setState({ form: null }, () => { this._onLoad() })
                                        });

                                    }}
                                    initialValues={this.state.form}
                                    fields={[
                                        {
                                            type: 'row',
                                            children: [


                                                // colorScheme: {
                                                //     header: {
                                                //       background: '#fff',
                                                //       text: '#9294A1'
                                                //     },
                                                //     tabs: {
                                                //       background: '#092651',
                                                //       text: '#fff',
                                                //     },
                                                //     screen: {
                                                //       background: '#F3F7FC',
                                                //       text: '#191C24',
                                                //       buttonBackground: '#8391A6',
                                                //       buttonText: '#fff'
                                                //     },
                                                //     container: {
                                                //       background: '#FFFFFF',
                                                //       text: '#191C24'
                                                //     }


                                                //   },
                                                {
                                                    type: 'col',
                                                    width: { lg: 12, sm: 12, xs: 12 },
                                                    children: [
                                                        {
                                                            type: 'block',
                                                            header: 'Banner config'.translate(this.props.lang),
                                                            style: { paddingBottom: 33, marginTop: 20 },
                                                            children: [
                                                                {
                                                                    type: 'col',
                                                                    width: { lg: 6, sm: 12, xs: 12 },
                                                                    children: [
                                                                        {
                                                                            type: 'text',
                                                                            name: 'bannerInterval',
                                                                            next: 'password',
                                                                            label: 'Banner interval [s]',
                                                                        },

                                                                    ]
                                                                },
                                                                {
                                                                    type: 'col',
                                                                    width: { lg: 12, sm: 12, xs: 12 },
                                                                    children: [
                                                                        {
                                                                            type: 'image',
                                                                            name: 'backgroundImage',
                                                                            next: 'stock',
                                                                            endpoint: '/data/leagues/upload',
                                                                            label: 'App Background',

                                                                        },

                                                                    ]
                                                                },



                                                            ]
                                                        },

                                                    ]
                                                },
                                                {
                                                    type: 'col',
                                                    width: { lg: 12, sm: 12, xs: 12 },
                                                    children: [
                                                        {
                                                            type: 'block',
                                                            header: 'Table config'.translate(this.props.lang),
                                                            style: { paddingBottom: 33, marginTop: 20 },
                                                            children: [
                                                                {
                                                                    type: 'col',
                                                                    width: { lg: 6, sm: 12, xs: 12 },
                                                                    children: [
                                                                        {
                                                                            type: 'text',
                                                                            name: 'tableUpText',
                                                                            next: 'password',
                                                                            label: 'Up Text',
                                                                        },

                                                                    ]
                                                                },
                                                                {
                                                                    type: 'col',
                                                                    width: { lg: 6, sm: 12, xs: 12 },
                                                                    children: [
                                                                        {
                                                                            type: 'text',
                                                                            name: 'tableDownText',
                                                                            next: 'password',
                                                                            label: 'Down Text',
                                                                        },

                                                                    ]
                                                                },



                                                            ]
                                                        },



                                                    ]
                                                },

                                                {
                                                    type: 'col',
                                                    width: { lg: 12, sm: 12, xs: 12 },
                                                    children: [
                                                        {
                                                            type: 'block',
                                                            header: 'Legal informations'.translate(this.props.lang),
                                                            style: { paddingBottom: 33, marginTop: 20 },
                                                            children: [
                                                                {
                                                                    type: 'col',
                                                                    width: { lg: 12, sm: 12, xs: 12 },
                                                                    children: [
                                                                        {
                                                                            type: 'html',
                                                                            name: 'legal',
                                                                            next: 'password',
                                                                            label: 'Text [LAT]',
                                                                        },

                                                                    ]
                                                                },

                                                                {
                                                                    type: 'col',
                                                                    width: { lg: 12, sm: 12, xs: 12 },
                                                                    children: typeof window !== 'undefined' && window.origin.indexOf('repka.rs') !== -1 ?
                                                                        [
                                                                            {
                                                                                type: 'html',
                                                                                name: 'legalCYR',
                                                                                next: 'password',
                                                                                label: 'Text [CYR]',
                                                                            },

                                                                        ] : []
                                                                },


                                                            ]
                                                        },



                                                    ]
                                                },



                                                {
                                                    type: 'col',
                                                    width: { lg: 12, sm: 12, xs: 12 },
                                                    children: [
                                                        {
                                                            type: 'block',
                                                            header: 'Color scheme'.translate(this.props.lang),
                                                            style: { paddingBottom: 33, marginTop: 20 },
                                                            children: [
                                                                {
                                                                    type: 'col',
                                                                    width: { lg: 2, sm: 12, xs: 12 },
                                                                    children: [
                                                                        {
                                                                            type: 'color',
                                                                            name: 'colorScheme.header.background',
                                                                            next: 'password',
                                                                            label: 'Header background',
                                                                            multilang: true,
                                                                        },

                                                                    ]
                                                                },
                                                                {
                                                                    type: 'col',
                                                                    width: { lg: 2, sm: 12, xs: 12 },
                                                                    children: [
                                                                        {
                                                                            type: 'color',
                                                                            name: 'colorScheme.header.text',
                                                                            next: 'password',
                                                                            label: 'Header text',
                                                                            multilang: true,
                                                                        },

                                                                    ]
                                                                },
                                                                {
                                                                    type: 'col',
                                                                    width: { lg: 2, sm: 12, xs: 12 },
                                                                    children: [
                                                                        {
                                                                            type: 'color',
                                                                            name: 'colorScheme.tabs.background',
                                                                            next: 'password',
                                                                            label: 'Tabs background',
                                                                            multilang: true,
                                                                        },

                                                                    ]
                                                                },
                                                                {
                                                                    type: 'col',
                                                                    width: { lg: 2, sm: 12, xs: 12 },
                                                                    children: [
                                                                        {
                                                                            type: 'color',
                                                                            name: 'colorScheme.screen.background',
                                                                            next: 'password',
                                                                            label: 'Screen background',
                                                                            multilang: true,
                                                                        },

                                                                    ]
                                                                },
                                                                {
                                                                    type: 'col',
                                                                    width: { lg: 2, sm: 12, xs: 12 },
                                                                    children: [
                                                                        {
                                                                            type: 'color',
                                                                            name: 'colorScheme.screen.text',
                                                                            next: 'password',
                                                                            label: 'Screen text',
                                                                            multilang: true,
                                                                        },

                                                                    ]
                                                                },

                                                                {
                                                                    type: 'col',
                                                                    width: { lg: 2, sm: 12, xs: 12 },
                                                                    children: [
                                                                        {
                                                                            type: 'color',
                                                                            name: 'colorScheme.screen.buttonBackground',
                                                                            next: 'password',
                                                                            label: 'Button background',
                                                                            multilang: true,
                                                                        },

                                                                    ]
                                                                },
                                                                {
                                                                    type: 'col',
                                                                    width: { lg: 2, sm: 12, xs: 12 },
                                                                    children: [
                                                                        {
                                                                            type: 'color',
                                                                            name: 'colorScheme.screen.buttonText',
                                                                            next: 'password',
                                                                            label: 'Button text',
                                                                            multilang: true,
                                                                        },

                                                                    ]
                                                                },
                                                                {
                                                                    type: 'col',
                                                                    width: { lg: 2, sm: 12, xs: 12 },
                                                                    children: [
                                                                        {
                                                                            type: 'color',
                                                                            name: 'colorScheme.container.background',
                                                                            next: 'password',
                                                                            label: 'Container background',
                                                                            multilang: true,
                                                                        },

                                                                    ]
                                                                },
                                                                {
                                                                    type: 'col',
                                                                    width: { lg: 2, sm: 12, xs: 12 },
                                                                    children: [
                                                                        {
                                                                            type: 'color',
                                                                            name: 'colorScheme.container.text',
                                                                            next: 'password',
                                                                            label: 'Container text',
                                                                            multilang: true,
                                                                        },

                                                                    ]
                                                                },






                                                            ]
                                                        },



                                                    ]
                                                },





                                            ],
                                        },
                                        {
                                            type: 'row',
                                            children: [
                                                {
                                                    type: 'button',
                                                    width: { lg: 4, sm: 6, xs: 6 },
                                                    action: 'submit',
                                                    children: 'Save',
                                                    className: 'form-button',
                                                },

                                            ]
                                        }

                                    ]}
                                ></FormBuilder>
                            </div>

                        </div>
                        :
                        null

                }

            </div>
        )
    }
}

export default Page(Home);

/*

*/