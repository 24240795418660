import { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

import { withRouter } from 'react-router-dom';
import DocumentMeta from 'react-document-meta';

import FormBuilder from './form-builder';
import Routes from './routes';
import { API_ENDPOINT } from './constants';


Object.translate = function (o, s, lang) {
  try {
    if (!o) {
      return '';
    }

    s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
    s = s.replace(/^\./, '');           // strip a leading dot
    var a = s.split('.');
    for (var i = 0, n = a.length; i < n; ++i) {
      var k = a[i];
      if (k in o) {
        o = o[k];
      } else {
        return;
      }
    }
    return o[lang] ? o[lang] : o['en'];
  } catch (e) {
    return '';
  }
}


Object.get = function (o, s) {
  try {
    //console.log(o, s)
    if (!o) {
      return null;
    }

    s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
    s = s.replace(/^\./, '');           // strip a leading dot
    var a = s.split('.');
    for (var i = 0, n = a.length; i < n; ++i) {
      var k = a[i];
      if (k in o) {
        o = o[k];
      } else {
        return;
      }
    }
    return o;
  } catch (e) {
    return null;
  }
}


if (String.prototype.translate == null) {
  String.prototype.translate = function (lang) {
    /* if (!localStorage.translate){
       localStorage.translate = JSON.stringify({
         'ba': {
  
         },
         'en': {
  
         }
       });
     }
  
     let obj = JSON.parse(localStorage.translate);
     obj.en[this] = this;
     obj.ba[this] = this;
 
     localStorage.translate = JSON.stringify(obj);
     
     return this;*/

    let langs = typeof window !== 'undefined' ? window.langs : null;

    if (!langs) {
      return this;
    }

    //console.log(Object.keys(langs), lang)
    if (langs[lang] && langs[lang][this]) {
      return langs[lang][this];
    }
    else return this;
  }
}


class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...props.appInitialData,
      sidebarShort: false,
      userVerificationInProgress: true,
      uData: null,
      modulesTree: [],
      availablePaths: [],
      lang: 'en'
    }
  }

  updateMeta = () => {

  }
  verifyUser = (callback) => {
    if (!localStorage.getItem('authToken')) {
      this.setState({
        userVerificationInProgress: false
      })
      return;
    }

    fetch(API_ENDPOINT + "/users/verify", {
      method: "GET",
      headers: {
        "content-type": "application/json",
        'Authorization': `Bearer ${localStorage.getItem('authToken')}`
      },
    })
      .then((res) => res.json())
      .then((result) => {
        console.log(result)
        if (result.error) {
          this.setState({
            error: result.error
          })
        } else {

          this.setState({ uData: result }, () => {
            if (callback) {
              callback()
            }
            fetch(API_ENDPOINT + '/users/modules/tree', {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('authToken')}`
              },
            }).then(res => {
              return res.json()
            }).then((result) => {
              if (!localStorage.getItem('authToken')) {
                this.setState({
                  userVerificationInProgress: false
                })
                return;
              }


              // if (result[0] && this.props.location.pathname == '/') {

              //   if (result[0].modules && result[0].modules[0]) {
              //     this.props.history.push(result[0].modules[0].link);
              //   } else {
              //     this.props.history.push(result[0].link);
              //   }
              // };

              // this.generateModulesMeta(result);
              this.setState({
                modulesTree: result
              })
            });

            fetch(API_ENDPOINT + '/users/modules/available-paths', {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('authToken')}`
              },
            }).then(res => {
              return res.json()
            }).then((result) => {
              if (!localStorage.getItem('authToken')) {
                this.setState({
                  userVerificationInProgress: false
                })
                return;
              }
              console.log(result)

              this.setState({
                availablePaths: result
              })
            });
          })



        }
      });
  }
  componentDidMount() {
    this.verifyUser()

  }

  signOut = (callback) => {
    if (typeof window != 'undefined') {
      localStorage.removeItem('authToken');
      this.setState({ uData: null }, () => {
        if (callback) {
          callback()
        }
      })
    }
  }

  changeLang = (lang) => {
    this.setState({ lang })
  }

  render() {
    return (
      <div className="App">
        <Routes
          updateMeta={this.updateMeta}
          verifyUser={this.verifyUser}
          signOut={this.signOut}
          changeLang={this.changeLang}
          {...this.props}
          {...this.state}
        ></Routes>
      </div >
    )
  }
}

export default App;
