import { Component } from "react";

import { FormGroup, Input, Label, FormFeedback } from 'reactstrap';
import { API_ENDPOINT } from "../../constants";

class Video extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {
        // console.log(this.props.readOnly ? this.props.val : this.props.value ? this.props.value : '')
        return (
            <FormGroup className="position-relative video-element">
                <Label>{this.props.label}</Label>
                { this.props.value?
                 ['.jpeg', '.jpg', '.png', '.bmp', '.gif', '.webp'].indexOf('.'+this.props.value.split('.').pop()) == -1 ?
                <video controls>
                    <source src={API_ENDPOINT+ this.props.value} />
                </video>
                :
                <img src={API_ENDPOINT+ this.props.value} />
                :
                null

    }

            </FormGroup>
        )
    }
}

export default Video;
