import React, { Component } from 'react';
import Page from '../../containers/page';
import FormBuilder from '../../form-builder';
import RouteComponent from '../../helpers/route';
import ListBuilder from '../../list-builder';
import Isvg from 'react-inlinesvg';
import {
    Container,
    Row,
    Col,
    Table,
    FormGroup, Label, Input
} from 'reactstrap';
import editIcon from '../../assets/edit.svg';
import garbageIcon from '../../assets/garbage.svg';
import printIcon from '../../assets/print.svg';
import saveIcon from '../../assets/save.svg';
import DeleteModal from '../../components/deleteModal';
import { required, number, minValue, maxValue, minLength, maxLength, length, email } from '../../form-builder/validation';
import Toogle from '../../form-builder/fields/switch';

import moment from 'moment';
class Screen extends RouteComponent {
    constructor(props) {
        super(props);
        this.state = {
            articlesList: true,
            articleForm: false,
            total: 20,
            items: [

            ],
            userLevels: []
        }
    }

    componentDidMount() {
        this._onLoad();
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props[0].location.search != prevProps[0].location.search) {
            this._onLoad();
        }
    }

    render() {
        let params = this._getParams();
        console.log(this.state)

        return (
            <div>
                <div className="section-title title-options">
                    <h1>{'User accounts [APP]'.translate(this.props.lang)}</h1>
                </div>

                <Container fluid style={{ paddingRight: 0 }}>
                    <DeleteModal
                        isOpen={this.state.deleteModal}
                        toggle={() => this.setState({ deleteModal: null })}
                        handler={() => {
                            this._api('api3', { data: this.state.deleteModal._id }, () => {
                                this.setState({ groupsForm: {} }, () => { this._onLoad(); this.setState({ deleteModal: null }) })
                            });
                        }} >
                        {'Delete user'.translate(this.props.lang)} <strong>{this.state.deleteModal ? this.state.deleteModal.username : ''}</strong> ?
                    </DeleteModal>
                    <Row>

                        <Col lg="12">
                            <div className="table-modifed ml-15 second-td-witdth-unset">
                                <ListBuilder
                                    tabs={false}
                                    sort={false}
                                    header={'List'}
                                    lang={this.props.lang}
                                    loading={this.state.loading}
                                    total={this.state.total}
                                    showNumeration={false}
                                    fields={[
                                        { type: 'text', name: 'email', label: 'Email'.translate(this.props.lang), multilang: false, allowSort: true },
                                        { type: 'text', name: 'username', label: 'Username'.translate(this.props.lang), multilang: false, allowSort: true },
                                        { type: 'text', name: 'name', label: 'FirstName'.translate(this.props.lang), multilang: false, allowSort: true },
                                        { type: 'text', name: 'lastName', label: 'LastName'.translate(this.props.lang), multilang: false, allowSort: true },
                                        { type: 'text', name: 'city', label: 'City'.translate(this.props.lang), multilang: false, allowSort: true },
                                        { type: 'text', name: 'country', label: 'Country'.translate(this.props.lang), multilang: false, allowSort: true },
                                        { type: 'text', name: 'tsCreated', label: 'CREATED'.translate(this.props.lang), multilang: false, allowSort: true },
                                        { type: 'text', name: 'isVerified', label: 'VERIFIED'.translate(this.props.lang), multilang: false, allowSort: true },
                                        { type: 'text', name: 'isBlocked', label: 'BLOCKED'.translate(this.props.lang), multilang: false, allowSort: true },
                                        { type: 'text', name: 'countOfFormus', label: 'FORUMS'.translate(this.props.lang), multilang: false, allowSort: false },
                                        { type: 'text', name: 'countOfComments', label: 'COMMENTS'.translate(this.props.lang), multilang: false, allowSort: false },
                                        { type: 'text', name: 'countOfReports', label: 'REPORTS'.translate(this.props.lang), multilang: false, allowSort: false },

                                    ]}
                                    items={this.state.items.map((item, idx) => {
                                        return {
                                            ...item,
                                            tsCreated: moment.unix(item.tsCreated).format('DD-MM-YYYY HH:mm'),
                                            isVerified: <div><Toogle value={item.isVerified} onChange={(value) => {
                                                this._api('api4', { data: { isVerified: value, _id: item._id } }, () => {
                                                    this._onLoad()
                                                });
                                            }} /></div>,
                                            isBlocked: <div><Toogle value={item.isBlocked} onChange={(value) => {
                                                this._api('api4', { data: { isBlocked: value, _id: item._id } }, () => {
                                                    this._onLoad()
                                                });
                                            }} /></div>,
                                            countOfReports: <div
                                                style={item.countOfReports >= 2 ?
                                                    { backgroundColor: '#dc3545', color: 'white', display: 'flex', alignItems: 'center', justifyContent: 'center' }
                                                    :
                                                    { display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                {item.countOfReports}
                                            </div>
                                        }
                                    })}
                                    actions={
                                        [
                                            // {
                                            //     component: <Isvg src={editIcon} className="delete-icon" />,
                                            //     onClick: (item) => this.setState({ form: null }, () => this.setState({ form: item }))
                                            //     //onClick: (item) => console.log(item)
                                            // },
                                            {
                                                component: <Isvg src={garbageIcon} className="delete-icon" />,
                                                onClick: (item) => this.setState({ deleteModal: item })
                                            },
                                        ]
                                    }
                                    params={params}
                                    sortField={params.sortField}
                                    sortType={params.sortType}
                                    updateSort={this.updateSort}
                                    updateParams={this.updateParams}
                                    updateMultipleParams={this.updateMultipleParams}
                                ></ListBuilder>
                            </div>
                        </Col>
                    </Row>
                </Container>

            </div>
        )
    }
}

export default Page(Screen);
