import { Component } from "react";

import { FormGroup, Input, Label, FormFeedback } from 'reactstrap';
import { API_ENDPOINT } from "../../constants";
import Text from './text';
import Switch from './switch';

class TeamField extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {
        // console.log(this.props.readOnly ? this.props.val : this.props.value ? this.props.value : '')
        let value = this.props.value ? this.props.value : {};

        return (
            <div className="team-field">
                <img src={API_ENDPOINT + this.props.teamImage} />
                <span>{this.props.teamName}</span>
                <div>
                    <Text setFieldRef={() => { }} onChange={(val) => {
                        value.position = val;
                        this.props.onChange(value);
                    }} value={value.position} label="POS"></Text>

                    <Text setFieldRef={() => { }} onChange={(val) => {
                        value.mp = val;
                        this.props.onChange(value);
                    }} value={value.mp} label="P"></Text>
                    <Text setFieldRef={() => { }} onChange={(val) => {
                        value.g = val;
                        this.props.onChange(value);
                    }} value={value.g} label="GD"></Text>
                    <Text setFieldRef={() => { }} onChange={(val) => {
                        value.pts = val;
                        this.props.onChange(value);
                    }} value={value.pts} label="PTS"></Text>
                    <div className="switches">
                        <Switch onChange={(val) => {
                        value.up = val;
                        this.props.onChange(value);
                    }} value={value.up}></Switch>
                        <Switch onChange={(val) => {
                        value.down = val;
                        this.props.onChange(value);
                    }} value={value.down}></Switch>
                    </div>
                </div>
            </div>
        )
    }
}

export default TeamField;
