import Screen from './screen';
import API from './api';
import Login from '../login/screen';
export default {
    path: '/home-config',
    auth: true,
    component: Screen,
    preAuthComponent: Login,
    api: API,
    
}