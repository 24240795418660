import React from 'react';
import Isvg from 'react-inlinesvg';
import editIcon from '../../assets/edit.svg';
import garbageIcon from '../../assets/garbage.svg';
import DeleteModal from '../../components/deleteModal';
import Page from '../../containers/page';
import FormBuilder from '../../form-builder';
import RouteComponent from '../../helpers/route';
import ListBuilder from '../../list-builder';


class Home extends RouteComponent {
    constructor(props) {
        super(props);
        this.state = {
            articlesList: true,
            articleForm: false,
            total: 20,
            items: [

            ],
            articleTypes: [],
            groups: [],
            subgroups: [],
            manufacturers: [],
            atc: [],
            shapes: [],
            modes: [],
            attributes: [],
            categories: []
        }
    }



    componentDidMount() {
        this._onLoad();

        this._api('categories', {}, (data) => {
            this.setState({ categories: data.categories })
        })

    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props[0].location.search != prevProps[0].location.search) {
            this._onLoad();
        }
    }

    render() {
        let params = this._getParams();

        console.log(this.state.categories)
        return (
            <div>
                <div className="section-title title-options">
                    <h1>{'Leagues'.translate(this.props.lang)}</h1>
                </div>
                {this.state.articleForm ?
                    <div className="table-tabs">
                        <button onClick={() => this.setState({ articleForm: false })}>
                            {'Leagues list'.translate(this.props.lamg)}
                        </button>

                        <button className="active" onClick={() => this.setState({ articleForm: {} })}>
                            {this.state.articleForm && this.state.articleForm._id ? 'Edit league'.translate(this.props.lang) : 'Add new league'.translate(this.props.lang)}
                        </button>
                    </div>
                    : null}

                {
                    this.state.articleForm ?

                        <div>
                            <div className="side-margin">
                                <FormBuilder
                                    registerSubmitAction={(submit) => this.submit = submit}
                                    classNameForm={"add-form-container"}
                                    // headerForm={"Artikal".translate(this.props.lang)}
                                    //                                  addButtonText={this.state.articleForm._id ? 'Edit product'.translate(this.props.lang) :  'Add new'.translate(this.props.lang)}
                                    lang={this.props.lang}
                                    onSubmit={(data) => {

                                        if (this.state.articleForm && this.state.articleForm._id) {
                                            console.log(data)
                                            this._api('api4', { data: data }, () => {
                                                this.setState({ articleForm: undefined }, () => { this._onLoad(); })
                                            });
                                        } else {
                                            console.log(data)
                                            this._api('api2', { data: data }, () => {
                                                this.setState({ articleForm: {} }, () => { this._onLoad() })
                                            });
                                        }
                                    }}
                                    initialValues={this.state.articleForm && this.state.articleForm._id ? this.state.articleForm : {}}
                                    fields={[
                                        {
                                            type: 'row',
                                            children: [
                                                {
                                                    type: 'col',
                                                    width: { lg: 8, sm: 8, xs: 8 },
                                                    children: [
                                                        {
                                                            type: 'block',
                                                            header: 'League info'.translate(this.props.lang),
                                                            style: { paddingBottom: 33 },
                                                            children: [

                                                                {
                                                                    type: 'col',
                                                                    width: { lg: 4, sm: 4, xs: 4 },
                                                                    children: [
                                                                        {
                                                                            type: 'text',
                                                                            name: 'name',
                                                                            next: 'name',
                                                                            label: 'Name',
                                                                        },

                                                                    ]
                                                                },

                                                                {
                                                                    type: 'col',
                                                                    width: { lg: 12, sm: 12, xs: 12 },
                                                                    children: [
                                                                        {
                                                                            type: 'switch',
                                                                            name: 'active',
                                                                            next: 'featured',
                                                                            label: 'Active',
                                                                        }
                                                                    ]
                                                                },

                                                            ]
                                                        },

                                                        {
                                                            type: 'block',
                                                            header: 'Product details'.translate(this.props.lang),
                                                            style: { marginTop: 15, },
                                                            children: [

                                                                {
                                                                    type: 'col',
                                                                    width: { lg: 12, sm: 12, xs: 12 },
                                                                    children: [

                                                                        {
                                                                            type: 'html',
                                                                            name: 'description',
                                                                            multilang: true,
                                                                            next: 'stock',
                                                                            label: 'Description',

                                                                        }
                                                                    ]
                                                                }

                                                            ]
                                                        },


                                                    ]
                                                },
                                                {
                                                    type: 'col',
                                                    width: { lg: 4, sm: 4, xs: 4 },
                                                    children: [

                                                        {
                                                            type: 'block',
                                                            header: 'Media'.translate(this.props.lang),
                                                            style: { marginLeft: 0, marginTop: 0 },
                                                            children: [
                                                                {
                                                                    type: 'col',
                                                                    width: { lg: 12, sm: 12, xs: 12 },
                                                                    children: [
                                                                        {
                                                                            type: 'image',
                                                                            name: 'image',
                                                                            next: 'stock',
                                                                            endpoint: '/data/products/assets/upload',
                                                                            label: 'Image',

                                                                        },

                                                                    ]

                                                                }
                                                            ]

                                                            // {
                                                            //     type: 'button',
                                                            //     width: { lg: 12, sm: 6, xs: 6 },
                                                            //     action: 'submit',
                                                            //     children: 'Save',
                                                            //     className: 'form-button',
                                                            // },


                                                        },



                                                    ]
                                                }, {
                                                    type: 'col',
                                                    width: { lg: 8, sm: 12, xs: 12 },
                                                    children: [


                                                    ]
                                                },
                                                {
                                                    type: 'col',
                                                    width: { lg: 8, sm: 8, xs: 8 },
                                                    children: [
                                                        {
                                                            type: 'block',
                                                            header: 'Attributes',
                                                            style: { paddingBottom: 33, marginTop: 20 },
                                                            children: this.state.attributes.map((attribute) => {

                                                                return {
                                                                    type: 'col',
                                                                    width: { lg: 4, sm: 4, xs: 4 },
                                                                    children: [
                                                                        {
                                                                            type: 'text',
                                                                            name: `productAttributes.${attribute.key}`,
                                                                            next: 'unit',
                                                                            label: attribute.name[this.props.lang],
                                                                        },


                                                                    ]
                                                                }
                                                            }


                                                            )

                                                        },
                                                    ]
                                                }





                                            ],
                                        },
                                        {
                                            type: 'row',
                                            children: [
                                                {
                                                    type: 'button',
                                                    width: { lg: 4, sm: 6, xs: 6 },
                                                    action: 'submit',
                                                    children: 'Save',
                                                    className: 'form-button',
                                                },

                                            ]
                                        }

                                    ]}
                                ></FormBuilder>
                            </div>

                        </div>

                        :

                        <div>
                            <DeleteModal
                                isOpen={this.state.deleteModal}
                                toggle={() => this.setState({ deleteModal: null })}
                                handler={() => {
                                    this._api('api3', { data: this.state.deleteModal._id }, () => {
                                        this.setState({ groupsForm: {} }, () => { this._onLoad(); this.setState({ deleteModal: null }) })
                                    });
                                }} >
                                {'Delete league'.translate(this.props.lang)} <strong>{this.state.deleteModal ? this.state.deleteModal.name[this.props.lang] : ''}</strong> ?
                            </DeleteModal>
                            <ListBuilder
                                tabs={true}
                                sort={true}
                                tab1Name={'Leagues list'.translate(this.props.lang)}
                                tab2Name={"Add new league".translate(this.props.lang)}
                                onClickTab1={() => {
                                    this.setState({
                                        articlesList: true,
                                        articleForm: false,
                                    })

                                }}
                                onClickTab2={() => {
                                    this.setState({
                                        articleForm: true,
                                        articlesList: false
                                    })
                                }}
                                lang={this.props.lang}
                                loading={this.state.loading}
                                total={this.state.total}
                                showNumeration={false}
                                fields={[
                                    { type: 'image', name: 'thumbnailExposed', label: ''.translate(this.props.lang), multilang: false, allowSort: false },

                                    { type: 'text', name: 'sku', label: 'SKU'.translate(this.props.lang), multilang: false, allowSort: true },
                                    { type: 'text', name: 'brand', label: 'BRAND'.translate(this.props.lang), multilang: false, allowSort: true },

                                    { type: 'text', name: 'name', label: 'NAME'.translate(this.props.lang), multilang: true, allowSort: true },
                                    { type: 'text', name: 'inStock', label: 'IN STOCK'.translate(this.props.lang), multilang: false, allowSort: true },
                                    { type: 'text', name: 'discount', label: 'SALE'.translate(this.props.lang), multilang: false, allowSort: true },
                                    { type: 'text', name: 'views', label: 'VIEWS'.translate(this.props.lang), multilang: false, allowSort: true },
                                ]}
                                items={this.state.items}

                                actions={
                                    [
                                        {
                                            component: <Isvg src={editIcon} />,
                                            onClick: (item) => this.setState({ articleForm: item })
                                        },
                                        {
                                            component: <Isvg src={garbageIcon} />,
                                            onClick: (item) => this.setState({ deleteModal: item })
                                        },
                                    ]
                                }

                                params={params}
                                sortField={params.sortField}
                                sortType={params.sortType}
                                updateSort={this.updateSort}
                                updateParams={this.updateParams}
                                updateMultipleParams={this.updateMultipleParams}
                            ></ListBuilder>
                        </div>
                }


            </div>
        )
    }
}

export default Page(Home);

/*

*/