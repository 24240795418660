import { Component } from "react";

import {
  Row,
  Col,
  Table,
  FormGroup, Label, Input
} from 'reactstrap';
import Text from "./fields/text";
import Button from "./fields/button";
import Textarea from "./fields/textarea";
import Switch from "./fields/switch";
import List from "./fields/list";
import Select from "./fields/select";
import Tags from "./fields/tags";
import Html from "./fields/html";
import MediaGallery from "./fields/productImages";
import Categories from "./fields/categories";
import File from "./fields/file";
import Image from "./fields/image";
import Blocks from "./fields/blocks";
import TeamField from "./fields/teamField";

import ImageSelect from "./fields/imageSelect";
import ProductSelect from "./fields/productSelect";
import Color from "./fields/color";
import Video from "./fields/video";

var _ = require("lodash");

const FIN_KEY = "Enter";

class FormBuilder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      errors: {},
      touched: {},
    };

    this.fieldRefs = {};
  }

  getAsyncFieldValidations = (fields, validations = {}) => {
    for (let i = 0; i < fields.length; i++) {
      if (fields[i] && fields[i].validate && fields[i].asyncValidation) {
        validations[fields[i].name] = fields[i].validate;
      }

      if (fields[i] && fields[i].children) {
        validations = {
          ...validations,
          ...this.getAsyncFieldValidations(fields[i].children, validations),
        };
      }
    }
    return validations;
  };
  getFieldValidations = (fields, validations = {}) => {
    for (let i = 0; i < fields.length; i++) {
      if (fields[i] && fields[i].validate) {
        validations[fields[i].name] = fields[i].validate;
      }

      if (fields[i] && fields[i].children) {
        validations = {
          ...validations,
          ...this.getFieldValidations(fields[i].children, validations),
        };
      }
    }
    return validations;
  };

  processValidation = async (value, validate) => {
    for (let i = 0; i < validate.length; i++) {
      let error = await validate[i](value, this.state.data);
      if (error) {
        return error;
      }
    }

    return null;
  };



  onChange = async (name, value, validate) => {
    let data = { ...this.state.data };

    _.set(data, name, value);
    this.setState({
      data,
    }, async () => {
      if (this.props.fieldsToTrack) {
        let idx = this.props.fieldsToTrack.indexOf(name);
        if (idx !== -1) {
          this.props.fieldTracker(this.props.fieldsToTrack[idx], _.get(this.state.data, this.props.fieldsToTrack[idx]))
        }
      }

      let touched = this.state.touched;
      let errors = this.state.errors;
      if (validate) {
        touched[name] = true;
        let validations = this.getAsyncFieldValidations(this.props.fields);
        for (var fieldName in touched) {
          if (validations[fieldName]) {
            errors[fieldName] = await this.processValidation(
              _.get(this.state.data, fieldName),
              validations[fieldName]
            );
          }
        }
      }

    });




  };

  remoteOnChange = (name, value) => {
    let data = this.state.data;
    let touched = this.state.touched;
    let errors = this.state.errors;

    _.set(data, name, value);

    this.setState({
      data,
      touched,
      errors,
    }, () => {
      if (this.props.fieldsToTrack) {
        let idx = this.props.fieldsToTrack.indexOf(name);
        if (idx !== -1) {
          this.props.fieldTracker(this.props.fieldsToTrack[idx], _.get(this.state.data, this.props.fieldsToTrack[idx]))
        }
      }

    });
  };


  getValue = (name) => {
    return _.get(this.state.data, name);
  };

  setFieldRef = (name, node) => {
    this.fieldRefs[name] = node;
  };

  focusNext = (from, to) => {
    if (to == 'submit') {
      this.submit();
    }

    if (!this.fieldRefs[from]) {
      return;
    }
    if (!this.fieldRefs[to]) {
      return;
    }


    this.fieldRefs[to].focus();
  };
  registerFocusedField = (name) => {
    if (name == this.state.focusedField) {
      return true;
    } else {
      return false;
    }
  }

  componentDidMount() {
    // if (this.props.initialValues) {
    //   this.setState({
    //     data: JSON.parse(JSON.stringify(this.props.initialValues)),
    //   });
    // }
    if (this.props.initialValues) {
      let data1 = JSON.parse(JSON.stringify(this.props.initialValues));
      let obj = {};
      for (var key in data1) {
        _.set(obj, key, data1[key]);
      }
      this.setState({
        data: obj,
      });
    }

    if (this.props.registerSubmitAction) {
      this.props.registerSubmitAction(this.submit);
    }

    if (this.props.registerChangeValue) {
      this.props.registerChangeValue(this.remoteOnChange);
    }

    if (this.props.registerGetValue) {
      this.props.registerGetValue(this.getValue);
    }
    if (this.props.registerFocusedField) {
      this.props.registerFocusedField(this.registerFocusedField);
    }


  }


  componentDidUpdate(prevProps, prevState) {




    // if (!prevProps.initialValues && this.props.initialValues) {

    //   this.setState({
    //     data: JSON.parse(JSON.stringify(this.props.initialValues)),
    //   });
    // }

    // if (prevProps.initialValues != this.props.initialValues && this.props.initialValues) {


    //   this.setState({
    //     data: JSON.parse(JSON.stringify(this.props.initialValues)),
    //   });

    // }
    // // if(JSON.stringify(prevState.data) != JSON.stringify(this.state.data)){
    // //   console.log(this.state.data)
    // // }


    if (!prevProps.initialValues && this.props.initialValues) {

      let data1 = JSON.parse(JSON.stringify(this.props.initialValues));
      let obj = {};
      for (var key in data1) {
        _.set(obj, key, data1[key]);
      }
      this.setState({
        data: obj,
      });
    }

    // if (prevProps.initialValues != this.props.initialValues && this.props.initialValues) {
    //   let data1 = JSON.parse(JSON.stringify(this.props.initialValues));
    //   let obj = {};
    //   for (var key in data1) {
    //     _.set(obj, key, data1[key]);
    //   }
    //   this.setState({
    //     data: obj,
    //   });
    // }



    // if (this.props.fieldsToTrack && this.props.fieldsToTrack.length) {
    //   for (let i = 0; i < this.props.fieldsToTrack.length; i++) {
    //     if (_.get({ ...prevState.data }, this.props.fieldsToTrack[i]) != _.get({ ...this.state.data }, this.props.fieldsToTrack[i])) {
    //       this.props.fieldTracker(this.props.fieldsToTrack[i], _.get(this.state.data, this.props.fieldsToTrack[i]))
    //     }
    //   }
    // }

  }

  // componentWillReceiveProps(nextProps, nextState) {

  //   if (this.props.fieldsToTrack && this.props.fieldsToTrack.length) {
  //     for (let i = 0; i < this.props.fieldsToTrack.length; i++) {
  //       if (_.get({ ...nextState.data }, this.props.fieldsToTrack[i]) != _.get({ ...this.state.data }, this.props.fieldsToTrack[i])) {
  //         this.props.fieldTracker(this.props.fieldsToTrack[i], _.get(this.state.data, this.props.fieldsToTrack[i]))
  //       }
  //     }
  //   }

  // }


  submit = async () => {
    let data = JSON.parse(JSON.stringify(this.state.data));


    let errors = this.state.errors;
    let touched = this.state.touched;




    let validations = this.getFieldValidations(this.props.fields);

    let foundError = false;

    for (var fieldName in validations) {
      if (validations[fieldName]) {
        errors[fieldName] = await this.processValidation(
          _.get(data, fieldName),
          validations[fieldName]
        );
        if (errors[fieldName]) {
          touched[fieldName] = true;
          foundError = true;
        }
      }
    }

    this.setState({
      errors,
      touched,
    });

    if (foundError) {
      return;
    }

    let check = this.props.onSubmit(data);
    if (check && check == 'open-modal') {
      return
    }

    data = {};
    errors = {};
    touched = {};
    this.setState({ data, errors, touched });

  }

  handleAction = async (button) => {
    if (button.action == "submit") {
      this.submit()

    } else if (button.action == "clear") {
      let data = {};
      let errors = {};
      let touched = {};
      this.setState({ data, errors, touched });
      if (this.props.onClear) {
        this.props.onClear()
      }
    } else if (button.action == 'custom') {
      button.onClick()



    }
  };



  renderFields = (fields) => {
    return fields.map((item, idx) => {
      if (!item)
        return null;

      if (item.type == 'row') {
        return <Row key={idx} className={item.className}> {this.renderFields(item.children)}</Row>;
      }
      else if (item.type == "block") {
        return <Row style={item.style ? item.style : {}} className={this.props.classNameForm} key={idx}>
          {
            item.header ?
              <div className="custom-header">
                <h6 style={{ display: 'block', width: '100%' }}>{item.header}</h6>
                {
                  item.referent ?
                    <span>Referent</span>
                    :
                    null
                }
              </div>
              :
              null
          }
          {this.renderFields(item.children)}</Row>;
      } else if (item.type == "col") {
        return (
          <Col
            key={idx}
            className={item.className}
            lg={{ size: item.width.lg }}
            sm={{ size: item.width.sm }}
            xs={{ size: item.width.xs }}
            style={item.style}
          >
            {this.renderFields(item.children)}
          </Col>
        );
      } else if (item.type == "text") {
        return (
          <Text
            key={idx}
            multilang={item.multilang}
            lang={this.props.lang}

            touched={this.state.touched[item.name]}
            error={this.state.errors[item.name]}
            finishKey={FIN_KEY}
            setFieldRef={(node) => this.setFieldRef(item.name, node)}
            focusNext={() => {
              if (item.next) this.focusNext(item.name, item.next);
            }}
            onChange={(value) =>
              this.onChange(item.name, value, item.asyncValidation)
            }
            readOnly={item.readOnly ? item.readOnly : ''}
            value={this.getValue(item.name)}
            textType={item.textType}
            label={item.label}
            placeholder={item.placeholder}
          ></Text>
        );
      } else if (item.type === "p") {
        return (
          <p
            key={idx}
            className={item.className} // Ako imate klasu
            style={item.style} // Ako imate stilove
          >
            {item.text} {/* Ovde prikazujete tekst */}
          </p>
        );
      } else if (item.type == "color") {
        return (
          <Color
            key={idx}
            multilang={item.multilang}
            lang={this.props.lang}

            touched={this.state.touched[item.name]}
            error={this.state.errors[item.name]}
            finishKey={FIN_KEY}
            setFieldRef={(node) => this.setFieldRef(item.name, node)}
            focusNext={() => {
              if (item.next) this.focusNext(item.name, item.next);
            }}
            onChange={(value) =>
              this.onChange(item.name, value, item.asyncValidation)
            }
            readOnly={item.readOnly ? item.readOnly : ''}
            value={this.getValue(item.name)}
            textType={item.textType}
            label={item.label}
            placeholder={item.placeholder}
          ></Color>
        );
      } else if (item.type == "blocks") {
        return (
          <Blocks
            key={idx}
            multilang={item.multilang}
            lang={this.props.lang}
            endpoint={item.endpoint}
            touched={this.state.touched[item.name]}
            error={this.state.errors[item.name]}
            finishKey={FIN_KEY}
            setFieldRef={(node) => this.setFieldRef(item.name, node)}
            focusNext={() => {
              if (item.next) this.focusNext(item.name, item.next);
            }}
            onChange={(value) =>
              this.onChange(item.name, value, item.asyncValidation)
            }
            readOnly={item.readOnly ? item.readOnly : ''}
            value={this.getValue(item.name)}
            textType={item.textType}
            videos={item.videos}
            label={item.label}
            placeholder={item.placeholder}
          ></Blocks>
        );
      } else if (item.type == "team") {
        return (
          <TeamField
            key={idx}
            multilang={item.multilang}
            lang={this.props.lang}
            endpoint={item.endpoint}
            touched={this.state.touched[item.name]}
            error={this.state.errors[item.name]}
            finishKey={FIN_KEY}
            setFieldRef={(node) => this.setFieldRef(item.name, node)}
            focusNext={() => {
              if (item.next) this.focusNext(item.name, item.next);
            }}
            onChange={(value) =>
              this.onChange(item.name, value, item.asyncValidation)
            }
            readOnly={item.readOnly ? item.readOnly : ''}
            value={this.getValue(item.name)}
            textType={item.textType}
            label={item.label}
            teamImage={item.teamImage}
            teamName={item.teamName}
            placeholder={item.placeholder}
          ></TeamField>
        );
      } else if (item.type == "select") {
        return (
          <Select
            key={idx}
            touched={this.state.touched[item.name]}
            error={this.state.errors[item.name]}
            finishKey={FIN_KEY}
            setFieldRef={(node) => this.setFieldRef(item.name, node)}
            focusNext={() => {
              if (item.next) this.focusNext(item.name, item.next);
            }}
            onChange={(value) =>
              this.onChange(item.name, value, item.asyncValidation)
            }
            value={this.getValue(item.name)}
            label={item.label}
            placeholder={item.placeholder}
            values={item.values}
          ></Select>
        );
      } else if (item.type == "image-select") {
        return (
          <ImageSelect
            key={idx}
            touched={this.state.touched[item.name]}
            error={this.state.errors[item.name]}
            finishKey={FIN_KEY}
            setFieldRef={(node) => this.setFieldRef(item.name, node)}
            focusNext={() => {
              if (item.next) this.focusNext(item.name, item.next);
            }}
            onChange={(value) =>
              this.onChange(item.name, value, item.asyncValidation)
            }
            value={this.getValue(item.name)}
            label={item.label}
            placeholder={item.placeholder}
            values={item.values}
          ></ImageSelect>
        );
      } else if (item.type == "product-select") {
        return (
          <ProductSelect
            key={idx}
            touched={this.state.touched[item.name]}
            error={this.state.errors[item.name]}
            finishKey={FIN_KEY}
            setFieldRef={(node) => this.setFieldRef(item.name, node)}
            focusNext={() => {
              if (item.next) this.focusNext(item.name, item.next);
            }}
            onChange={(value) =>
              this.onChange(item.name, value, item.asyncValidation)
            }
            value={this.getValue(item.name)}
            label={item.label}
            placeholder={item.placeholder}
          ></ProductSelect>
        );
      } else if (item.type == "categories") {
        return (
          <Categories
            key={idx}
            touched={this.state.touched[item.name]}
            error={this.state.errors[item.name]}
            finishKey={FIN_KEY}
            setFieldRef={(node) => this.setFieldRef(item.name, node)}
            focusNext={() => {
              if (item.next) this.focusNext(item.name, item.next);
            }}
            onChange={(value) =>
              this.onChange(item.name, value, item.asyncValidation)
            }
            value={this.getValue(item.name)}
            label={item.label}
            placeholder={item.placeholder}
            categories={item.categories}
          ></Categories>
        );
      } else if (item.type == "tags") {
        return (
          <Tags
            key={idx}
            touched={this.state.touched[item.name]}
            error={this.state.errors[item.name]}
            finishKey={FIN_KEY}
            setFieldRef={(node) => this.setFieldRef(item.name, node)}
            focusNext={() => {
              if (item.next) this.focusNext(item.name, item.next);
            }}
            onChange={(value) =>
              this.onChange(item.name, value, item.asyncValidation)
            }
            value={this.getValue(item.name)}
            label={item.label}
            placeholder={item.placeholder}
          ></Tags>
        );
      } else if (item.type == "textarea") {
        return (
          <Textarea
            key={idx}
            touched={this.state.touched[item.name]}
            error={this.state.errors[item.name]}
            finishKey={FIN_KEY}
            setFieldRef={(node) => this.setFieldRef(item.name, node)}
            focusNext={() => {
              if (item.next) this.focusNext(item.name, item.next);
            }}
            onChange={(value) =>
              this.onChange(item.name, value, item.asyncValidation)
            }
            value={this.getValue(item.name)}
            label={item.label}
            placeholder={item.placeholder}
          ></Textarea>
        );

      } else if (item.type == "file") {
        return (
          <File
            key={idx}
            touched={this.state.touched[item.name]}
            error={this.state.errors[item.name]}
            finishKey={FIN_KEY}
            setFieldRef={(node) => this.setFieldRef(item.name, node)}
            focusNext={() => {
              if (item.next) this.focusNext(item.name, item.next);
            }}
            onChange={(value) =>
              this.onChange(item.name, value, item.asyncValidation)
            }
            endpoint={item.endpoint}
            value={this.getValue(item.name)}
            label={item.label}
          ></File>
        );
      } else if (item.type == "image") {
        return (
          <Image
            key={idx}
            touched={this.state.touched[item.name]}
            error={this.state.errors[item.name]}
            finishKey={FIN_KEY}
            setFieldRef={(node) => this.setFieldRef(item.name, node)}
            focusNext={() => {
              if (item.next) this.focusNext(item.name, item.next);
            }}
            onChange={(value) =>
              this.onChange(item.name, value, item.asyncValidation)
            }
            endpoint={item.endpoint}
            value={this.getValue(item.name)}
            label={item.label}
          ></Image>
        );
      } else if (item.type == "html") {
        return (
          <Html
            key={idx}
            touched={this.state.touched[item.name]}
            error={this.state.errors[item.name]}
            finishKey={FIN_KEY}
            setFieldRef={(node) => this.setFieldRef(item.name, node)}
            focusNext={() => {
              if (item.next) this.focusNext(item.name, item.next);
            }}
            onChange={(value) =>
              this.onChange(item.name, value, item.asyncValidation)
            }
            value={this.getValue(item.name)}
            label={item.label}
            multilang={item.multilang}
            lang={this.props.lang}
            placeholder={item.placeholder}
            height={item.height}
          ></Html>
        );

      } else if (item.type == "video") {
        return (
          <Video
            key={idx}
            touched={this.state.touched[item.name]}
            error={this.state.errors[item.name]}
            finishKey={FIN_KEY}
            setFieldRef={(node) => this.setFieldRef(item.name, node)}
            focusNext={() => {
              if (item.next) this.focusNext(item.name, item.next);
            }}
            onChange={(value) =>
              this.onChange(item.name, value, item.asyncValidation)
            }
            value={this.getValue(item.name)}
            label={item.label}
            multilang={item.multilang}
            lang={this.props.lang}
            placeholder={item.placeholder}
            height={item.height}
          ></Video>
        );

      } else if (item.type == "media-gallery") {
        return (
          <MediaGallery
            key={idx}
            touched={this.state.touched[item.name]}
            error={this.state.errors[item.name]}
            finishKey={FIN_KEY}
            endpoint={item.endpoint}
            setFieldRef={(node) => this.setFieldRef(item.name, node)}
            focusNext={() => {
              if (item.next) this.focusNext(item.name, item.next);
            }}
            onChange={(value) =>
              this.onChange(item.name, value, item.asyncValidation)
            }
            value={this.getValue(item.name)}
            label={item.label}
            placeholder={item.placeholder}
          ></MediaGallery>
        );

      } else if (item.type == "switch") {
        return (
          <Switch
            key={idx}
            touched={this.state.touched[item.name]}
            error={this.state.errors[item.name]}
            finishKey={FIN_KEY}
            setFieldRef={(node) => this.setFieldRef(item.name, node)}
            focusNext={() => {
              if (item.next) this.focusNext(item.name, item.next);
            }}
            onChange={(value) =>
              this.onChange(item.name, value, item.asyncValidation)
            }
            value={this.getValue(item.name)}
            label={item.label}
            placeholder={item.placeholder}
          ></Switch>
        );
      } else if (item.type == "list") {
        return (
          <List
            key={idx}
            touched={this.state.touched[item.name]}
            error={this.state.errors[item.name]}
            finishKey={FIN_KEY}
            setFieldRef={(node) => this.setFieldRef(item.name, node)}
            focusNext={() => {
              if (item.next) this.focusNext(item.name, item.next);
            }}
            onChange={(value) =>
              this.onChange(item.name, value, item.asyncValidation)
            }
            value={this.getValue(item.name)}
            label={item.label}
            placeholder={item.placeholder}
            height={item.height}
          ></List>
        );
      } else if (item.type == "button") {
        return (
          <Button
            key={idx}
            className={item.className}
            color={item.color}
            onClick={() => this.handleAction(item)}
          >
            {item.children}
          </Button>
        );
      }
    });
  };


  render() {
    //console.log(this.state.data, this.getValue("firstName.textValue"));

    return (
      <div>
        <div>
          <div className={`form`}>
            {this.renderFields(this.props.fields)}
          </div>
        </div>
      </div>
    );
  }
}

export default FormBuilder;
