import React, { Component } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
  } from "react-router-dom";
import { createBrowserHistory } from "history";

import { routes } from './routesList';
import Page from '../containers/page';
const history = createBrowserHistory();

class Routes extends Component {

 
    render() {
        return (
            <div>
                <Router history = {history}>
                    <Switch className="react-switch">
                        {
                            routes.map((route) => {
                                if (route.auth) {   
                                    if (!this.props.uData) {
                                        
                                        return (
                                            <Route
                                                key={route.path}
                                                path={route.path}
                                                exact
                                                render={(...renderProps) => {
                                                    const Component = route.preAuthComponent;
                                                    return (
                                                        <Component {...renderProps} {...this.props} loadData={route.loadData} generateSeoTags={route.generateSeoTags} />
                                                    )
                                                }}
                                            />
    
                                        )
                                    } else {
                                        return (
                                            <Route
                                                key={route.path}
                                                path={route.path}
                                                exact
                                                render={(...renderProps) => {
                                                    const Component = route.component;
                                                    return (
                                                        <Component {...renderProps} {...this.props} api={route.api} seo={route.seo} />
                                                    )
                                                }}
                                            />

                                        )
                                    }
                                } else {
                                    return (
                                        <Route
                                            key={route.path}
                                            path={route.path}
                                            exact
                                            render={(...renderProps) => {
                                                const Component = route.component;
                                                return (
                                                    <Component {...renderProps} {...this.props} api={route.api} seo={route.seo} />
                                                )
                                            }}
                                        />

                                    )
                                }
                            })
                        }
                    </Switch>
                </Router>
            </div>
        );
    }
}

export default Routes;