import Screen from './screen';
import API from './api';
import Login from '../login/screen';

export default {
    path: '/matches/pages',
    auth: true,
    component: Screen,
    preAuthComponent: Login,
    api: API,
}