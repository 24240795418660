import { Component } from "react";

import { FormGroup, Input, Label, FormFeedback } from 'reactstrap';
import { SketchPicker } from 'react-color';

class Color extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {
        // console.log(this.props.readOnly ? this.props.val : this.props.value ? this.props.value : '')
        return (
            <FormGroup className="position-relative color-picker-group">
                <Label>{this.props.label}</Label>
                <button className="color-picker" type="button" onClick={() => this.setState({ showPicker: true })} style={{ backgroundColor: this.props.value }}></button>
                {this.state.showPicker ?
                    <>
                        <div className="color-picker-overlay" onClick={() => this.setState({ showPicker: false })}></div>
                        <SketchPicker color={this.props.value} onChangeComplete={(color) => { this.props.onChange(color.hex) }}></SketchPicker>
                    </>
                    :
                    null
                }
            </FormGroup>
        )
    }
}

export default Color;
