import LoginPage from './login';
import ArticlesPage from './articles';
import UsersPage from './users';
import UserLevelsPage from './userLevels';
import ModulesPage from './modules';
import CategoriesPage from './categories';

import CategoriesSortPage from './categories-sort';
import ClientsPage from './clients';
import Leagues from './leagues';
import HomeConfig from './home-config';
import TeamsPage from './teams';
import LeagePagesPage from './league-pages';
import MatchesPage from './matches';
import MatchePagesPage from './match-pages';
import NewsPage from './news';
import VideosPage from './videos';
import BannersPage from './banners';
import PollMatch from './match-pages-poll';
import PollLeague from './leagues-poll';
import CommentsPage from './comments';
import ForumPage from './forum';
import UsersAppPage from './usersApp';

export const routes = [
    LoginPage,
    ArticlesPage,
    UsersPage,
    UserLevelsPage,
    ModulesPage,
    CategoriesPage,
    CategoriesSortPage,
    ClientsPage,
    Leagues,
    HomeConfig,
    TeamsPage,
    LeagePagesPage,
    MatchesPage,
    NewsPage,
    VideosPage,
    MatchePagesPage,
    BannersPage,
    PollMatch,
    PollLeague,
    CommentsPage,
    UsersAppPage,
    ForumPage
]

