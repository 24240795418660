import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';

import Isvg from 'react-inlinesvg';

import {
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Label,
    FormGroup

} from 'reactstrap';
import { API_ENDPOINT } from '../../constants';

class ImageSelect extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    render() {
        //console.log(this.props.children);
        return (
            <FormGroup className="image-select">
                {this.props.label ? <Label className={this.props.error ? "required" : ""}>{this.props.label}</Label> : null}

                <Dropdown className={this.props.error ? 'select-field required' : 'select-field'} isOpen={this.state.dropdownOpen} toggle={() => { this.setState({ dropdownOpen: !this.state.dropdownOpen }) }}>
                    <DropdownToggle nav caret>
                        {
                            this.props.value ? <><img src={API_ENDPOINT + this.props.value.thumbnail} /> {this.props.value.filename} </> :  null
                        }
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-animation">
                        {
                            this.props.values && this.props.values.map((item) => {
                                if (item)
                                    return (
                                        <DropdownItem onClick={() => { this.props.onChange(item); }}><img src={API_ENDPOINT + item.thumbnail}/> {item.filename}</DropdownItem>
                                    )
                            })
                        }
                    </DropdownMenu>
                </Dropdown>
            </FormGroup>
        );
    }
}

export default ImageSelect;