import React from 'react';
import Isvg from 'react-inlinesvg';
import {
    Col,
    Container,
    Row
} from 'reactstrap';
import editIcon from '../../assets/edit.svg';
import garbageIcon from '../../assets/garbage.svg';
import saveIcon from '../../assets/save.svg';
import DeleteModal from '../../components/deleteModal';
import Page from '../../containers/page';
import FormBuilder from '../../form-builder';
import { required } from '../../form-builder/validation';
import RouteComponent from '../../helpers/route';
import ListBuilder from '../../list-builder';

// SORT START
import { SortableContainer, SortableElement } from 'react-sortable-hoc';

import arrayMove from 'array-move';

const SortableItem = SortableElement(({ value, className }) => <li className={className}> <h6> {value.name}</h6></li>);

const SortableList = SortableContainer(({ items, lang, dragIndex }) => {
    return (
        <ul className="sort-list">
            {items.map((value, index) => (
                <SortableItem parent={index} className={dragIndex === index ? 'drag' : null} key={`item-${value}`} index={index} value={value} lang={lang} itemIndex={index} />
            ))}
        </ul>
    );
});

// SORT END

class Screen extends RouteComponent {
    constructor(props) {
        super(props);
        this.state = {
            articlesList: true,
            articleForm: false,
            total: 20,
            modules: [],
            sortItems: [],
            items: [

            ]
        }
    }

    // SORT START
    onSortEnd = ({ oldIndex, newIndex }) => {
        this.setState(({ sortItems }) => ({
            sortItems: arrayMove(sortItems, oldIndex, newIndex),
            dragIndex: null
        }));
    };

    updatePosition = () => {
        let positions = [];
        let sortItems = this.state.sortItems;
        for (let i = 0; i < sortItems.length; i++) {
            positions.push({
                _id: sortItems[i]._id,
                position: i
            });

        }
        this._api('save-sort', { positions }, () => {
            if (typeof window != 'undefined') {
                window.location.reload()
            }
        });
    }

    // SORT END



    componentDidMount() {
        this._onLoad();
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props[0].location.search != prevProps[0].location.search) {
            this._onLoad();
        }
    }

    render() {
        let params = this._getParams();
        console.log(this.state)

        return (
            <div>
                <div className="section-title title-options">
                    <h1>Leagues</h1>
                </div>

                <Container fluid style={{ paddingRight: 0 }}>
                    <DeleteModal
                        isOpen={this.state.deleteModal}
                        toggle={() => this.setState({ deleteModal: null })}
                        handler={() => {
                            this._api('api3', { data: this.state.deleteModal._id }, () => {
                                this.setState({ groupsForm: {} }, () => { this._onLoad(); this.setState({ deleteModal: null }) })
                            });
                        }} >
                        Delete league <strong>{this.state.deleteModal ? this.state.deleteModal.name : ''}</strong> ?
                    </DeleteModal>
                    <Row>
                        <Col lg="6">
                            <FormBuilder
                                registerSubmitAction={(submit) => this.submit = submit}
                                classNameForm={"add-form-container"}
                                // headerForm={"Artikal".translate(this.props.lang)}
                                addButtonText={'Add'.translate(this.props.lang)}
                                onSubmit={(dat) => {

                                    let data = { ...dat };



                                    if (this.state.form && this.state.form._id) {

                                        this._api('api4', { data: data }, () => {
                                            this.setState({ form: null }, () => { this._onLoad(); })
                                        });
                                    } else {

                                        this._api('api2', { data: data }, () => {
                                            this.setState({ form: null }, () => { this._onLoad() })
                                        });
                                    }
                                }}
                                initialValues={this.state.form ? this.state.form : null}
                                fields={[
                                    {
                                        type: 'row',
                                        children: [
                                            {
                                                type: 'col',
                                                width: { lg: 12, sm: 12, xs: 12 },
                                                children: [
                                                    {
                                                        type: 'block',
                                                        header: 'League info'.translate(this.props.lang),
                                                        children: [
                                                            {
                                                                type: 'col',
                                                                width: { lg: 12, sm: 12, xs: 12 },
                                                                children: [
                                                                    {
                                                                        type: 'text',
                                                                        name: 'name',
                                                                        label: 'Name',
                                                                        asyncValidation: true,
                                                                        validate: [
                                                                            required('Required!'),
                                                                        ]
                                                                    },

                                                                ]
                                                            },
                                                            {
                                                                type: 'col',
                                                                width: { lg: 12, sm: 12, xs: 12 },
                                                                children: [
                                                                    {
                                                                        type: 'image',
                                                                        name: 'image',
                                                                        next: 'stock',
                                                                        endpoint: '/data/leagues/upload',
                                                                        label: 'Image',

                                                                    },

                                                                ]
                                                            },

                                                            {
                                                                type: 'col',
                                                                width: { lg: 12, sm: 12, xs: 12 },
                                                                children: this.state.form && this.state.form.teamsArray ?
                                                                    this.state.form.teamsArray.map((team, tidx) => {
                                                                        return {
                                                                            type: 'team',
                                                                            name: 'teams.' + team._id,
                                                                            next: 'stock',
                                                                            teamName: team.name,
                                                                            teamImage: team.image
                                                                        }




                                                                    })
                                                                    :
                                                                    [],

                                                            },
                                                            {
                                                                type: 'col',
                                                                width: { lg: 12, sm: 12, xs: 12 },
                                                                children: [
                                                                    {
                                                                        type: 'switch',
                                                                        name: 'hideTable',
                                                                        next: 'level',
                                                                        label: 'Hide table',
                                                                    },

                                                                ]
                                                            },
                                                            {
                                                                type: 'col',
                                                                width: { lg: 12, sm: 12, xs: 12 },
                                                                children: [
                                                                    {
                                                                        type: 'text',
                                                                        name: 'position',
                                                                        label: 'Position',
                                                                        asyncValidation: true,
                                                                        validate: [
                                                                            required('Required!'),
                                                                        ]
                                                                    },

                                                                ]
                                                            },


                                                            {
                                                                type: 'col',
                                                                width: { lg: 12, sm: 12, xs: 12 },
                                                                children: [
                                                                    {
                                                                        type: 'switch',
                                                                        name: 'active',
                                                                        next: 'level',
                                                                        label: 'Active',
                                                                    },

                                                                ]
                                                            },


                                                        ]
                                                    },



                                                ],

                                            },
                                            {
                                                type: 'button',
                                                width: { lg: 12, sm: 6, xs: 6 },
                                                action: 'submit',
                                                children: 'Save',
                                                className: 'form-button',
                                            },
                                        ]
                                    },


                                ]}
                            ></FormBuilder>
                        </Col>
                        <Col lg="6">
                            <div className="table-modifed ml-15">
                                <ListBuilder
                                    tabs={false}
                                    sort={false}
                                    header={'List'}
                                    lang={this.props.lang}
                                    loading={this.state.loading}
                                    total={this.state.total}
                                    showNumeration={false}
                                    fields={[
                                        { type: 'text', name: 'name', label: 'NAME'.translate(this.props.lang), multilang: false, allowSort: true },

                                    ]}
                                    items={this.state.items}
                                    actions={
                                        [
                                            {
                                                component: <Isvg src={editIcon} className="delete-icon" />,
                                                onClick: (item) => {




                                                    this.setState({ form: null }, () => this.setState({ form: item }))

                                                }
                                                //onClick: (item) => console.log(item)
                                            },
                                            {
                                                component: <Isvg src={garbageIcon} className="delete-icon" />,
                                                onClick: (item) => this.setState({ deleteModal: item })
                                            },
                                        ]
                                    }
                                    params={params}
                                    sortField={params.sortField}
                                    sortType={params.sortType}
                                    updateSort={this.updateSort}
                                    updateParams={this.updateParams}
                                    updateMultipleParams={this.updateMultipleParams}
                                ></ListBuilder>
                            </div>
                            {/* SORT START */}
                            <div className="sort-modules-wrap">
                                <div className="sort-modules-title">
                                    <h4>Sort</h4>
                                    <button onClick={this.updatePosition}>
                                        <div className="option green">
                                            <Isvg src={saveIcon} />
                                        </div>
                                        <span>Save</span>
                                    </button>
                                </div>

                                <SortableList onSortStart={({ node, index }) => {
                                    this.setState({ dragIndex: index })
                                }}

                                    dragIndex={this.state.dragIndex}
                                    hideSortableGhost={false}
                                    lang={this.props.lang}
                                    items={this.state.sortItems}
                                    onSortEnd={this.onSortEnd} />

                            </div>
                            {/* SORT END */}

                        </Col>
                    </Row>
                </Container>

            </div>
        )
    }
}

export default Page(Screen);
