import env from "react-dotenv";
import { parseJSON } from "../../helpers/json";
import { API_ENDPOINT } from "../../constants";
export default {
    'api1': {
        onLoad: true,
        action: (lang, match, query) => {
            return fetch(API_ENDPOINT + '/data/match-pages', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },

                body: JSON.stringify({
                    page: query.page,
                    entries: query.entries,
                    filter: query.filter,
                    sortType: query.sortType,
                    sortField: query.sortField,
                    query: query.status == 'active' ? {active: true} : query.status == 'inactive' ? {active: false} : {}

                })
            }).then(parseJSON).then(({ result, status }) => {
                if (status >= 200 && status < 300)
                    return {
                        items: result.items,
                        total: result.total,
                    }
            })
        }
    },
    'api2': {
        onLoad: false,
        action: (data) => {
            return fetch(API_ENDPOINT + '/data/match-pages/new', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
                body: JSON.stringify(data.data)
            }).then(parseJSON).then(({ result, status }) => {
                return { result, status };
            })
        }
    },

    'api3': {
        onLoad: false,
        action: (data) => {
            return fetch(API_ENDPOINT + '/data/match-pages/' + data.data, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
                //body: JSON.stringify(data)
            }).then(parseJSON).then(({ result, status }) => {
                return { result, status };
            })
        }
    },

    'api4': {
        onLoad: false,
        action: (data) => {
            return fetch(API_ENDPOINT + '/data/match-pages/' + data.data._id, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
                body: JSON.stringify(data.data)
            }).then(parseJSON).then(({ result, status }) => {
                return { result, status };
            })
        }
    },
    'matches': {
        onLoad: true,
        action: (lang, match, query) => {
            return fetch(API_ENDPOINT + '/data/matches', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },

                body: JSON.stringify({
                    page: null,
                    entries: null,
                    filter: null,
                    sortType: 1,
                    sortField: 'name',
                    query: { active: true }

                })
            }).then(parseJSON).then(({ result, status }) => {
                if (status >= 200 && status < 300)
                    return {
                        matches: result,
                    }
            })
        }
    },
    'push': {
        onLoad: false,
        action: (data) => {
            return fetch(API_ENDPOINT + '/data/match-pages/push/' + data.data, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
                //body: JSON.stringify(data)
            }).then(parseJSON).then(({ result, status }) => {
                return { result, status };
            })
        }
    },

    'videos': {
        onLoad: true,
        action: (lang, match, query) => {
            return fetch(API_ENDPOINT + '/data/videos', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },

                body: JSON.stringify({
                    page: null,
                    entries: null,
                    filter: null,
                    sortType: 1,
                    sortField: 'name',

                })
            }).then(parseJSON).then(({ result, status }) => {
                if (status >= 200 && status < 300)
                    return {
                        videos: result,
                    }
            })
        }
    },

    // SORT START
    'all': {
        onLoad: true,
        action: (lang, match, query) => {
            return fetch(API_ENDPOINT + '/data/match-pages', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },

                body: JSON.stringify({
                    page: 0,
                    entries: null,
                    filter: null,
                    sortType: 1,
                    sortField: 'position',

                })
            }).then(parseJSON).then(({ result, status }) => {
                if (status >= 200 && status < 300)
                    return {
                        sortItems: result
                    }
            })
        }
    },
    'save-sort': {
        onLoad: false,
        action: (positions) => {
            return fetch(API_ENDPOINT + '/sort/match-pages', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },

                body: JSON.stringify({ items: positions && positions.positions })
            }).then(parseJSON).then(({ result, status }) => {
                console.log(result)

            })
        }
    },

    // SORT END

}