import { Component } from "react";

import Isvg from 'react-inlinesvg';

import { FormGroup, Input, Label, FormFeedback } from 'reactstrap';
import Button from './button';
import HTML from './html';
import Image from './image';
import garbageIcon from '../../assets/garbage.svg';
import Select from './select'
class Blocks extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {
        let value = this.props.value ? this.props.value : [];


        return (
            <div className="blocks-field">
                {
                    value.map((item, idx) => {
                        if (item.type == 'media') {
                            return (
                                <div className="block-field">
                                    <div className="media-controls">
                                        {this.props.videos && this.props.videos.length ?
                                            <Select values={[{ name: 'None', value: null }, ...this.props.videos.map((video, idx) => { return { name: video.title, value: video.video } })]} value={item.value} onChange={(val) => {
                                                value[idx].value = val;
                                                this.props.onChange(value);

                                            }} label={'Select from video uploads'} setFieldRef={() => { }} ></Select>
                                            :
                                            null
                                        }
                                        <button type="button" onClick={() => {
                                            value.splice(idx, 1);
                                            this.props.onChange(value);
                                        }}><Isvg src={garbageIcon} /></button>
                                    </div>
                                    <Image endpoint={this.props.endpoint} onChange={(image) => {
                                        value[idx].value = image;
                                        this.props.onChange(value);
                                    }} value={item.value}></Image>
                                </div>
                            )
                        } else {
                            return (
                                <div className="block-field">
                                    <button type="button" onClick={() => {
                                        value.splice(idx, 1);
                                        this.props.onChange(value);
                                    }}><Isvg src={garbageIcon} /></button>

                                    <HTML value={item.value} onChange={(text) => {
                                        value[idx].value = text;
                                        this.props.onChange(value);
                                    }}></HTML>
                                    <br />
                                    {typeof window !== 'undefined' && window.origin.indexOf('repka.rs') !== -1 ?
                                        <HTML value={item.valueCYR} onChange={(text) => {
                                            value[idx].valueCYR = text;
                                            this.props.onChange(value);
                                        }}></HTML>
                                        :
                                        null
                                    }

                                </div>

                            )
                        }
                    })
                }

                <div className="controls">
                    <Button type="button" size="sm" onClick={() => {
                        value.push({ type: 'text' });
                        this.props.onChange(value);
                    }}>New text block</Button>
                    <Button type="button" size="sm" onClick={() => {
                        value.push({ type: 'media' });
                        this.props.onChange(value);
                    }}>New media block</Button>

                </div>
            </div>
        )
    }
}

export default Blocks;
